import React, { useContext, useEffect, useState } from "react";
import "./VillagePage.css";
import "./VillagePage_Resp.css";
import { EditionContext, LangContext } from "../../App";
import { BackendClient } from "../../utils/api_request";
import { map_A_Map, multiPartitionBy } from "../../utils/utils";
import { useSearchParams } from "react-router-dom";
import { VillageUp, Link } from "../../assets";
import { MarkdownText } from "../../components/markdown/MarkdownText";

const VillagePage = () => {
  const [villages, setVillages] = useState();
  const { lang } = useContext(LangContext);
  const [visibleVillages, setVisibleVillages] = useState();
  const [categoryCount, setCategoryCount] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [thematicVillages, setThematicVillages] = useState();
  const { edition } = useContext(EditionContext);
  useEffect(() => {
    BackendClient.post("/thematic_villages", { edition: edition._id })
      .then((data) => data.data)
      .then((data) => {
        const result = new Map();
        data.data.forEach((item) => result.set(item._id, item));
        setThematicVillages(result);
      });
    BackendClient.post("/villages", { edition: edition._id })
      .then((data) => data.data)
      .then((data) => {
        setVillages(data.data);
        setVisibleVillages(data.data);
        setCategoryCount(
          map_A_Map(
            multiPartitionBy(data.data, (village) =>
              village.categories.map((item) => item._id)
            ),
            (arr) => arr.length
          )
        );
      });
  }, [edition._id]);
  useEffect(() => {
    if (!villages) {
      return;
    }
    if (!searchParams.get("category")) {
      setVisibleVillages(villages);
      return;
    }
    setVisibleVillages(
      villages.filter((village) =>
        village.categories
          .map((item) => item._id)
          .includes(searchParams.get("category"))
      )
    );
  }, [searchParams, villages]);
  if (!villages || !categoryCount)
    return (
      <div className="village-page">
        <p>Loading</p>
      </div>
    );
  return (
    <div className="village-page">
      <p>{edition.translations["innovation_villages/title"][lang]}</p>
      <div className="village-page-showoff">
        <MarkdownText>
          {edition.translations["innovation_villages/text"][lang]}
        </MarkdownText>
      </div>
      <select
        onChange={(event) => {
          if (event.target.selectedIndex === 0) {
            setSearchParams({});
            return;
          }
          setSearchParams({ category: event.target.value });
        }}
      >
        <option>
          {edition.translations["innovation_villages/all_villages"][lang]}
        </option>
        {[...categoryCount.keys()]
          .map((key) => thematicVillages.get(key))
          .map((category, index) => (
            <option key={index} value={category._id}>
              {category.title[lang]} ({categoryCount.get(category._id)})
            </option>
          ))}
      </select>
      <div className="village-page-villages">
        {visibleVillages.map((village, index) => (
          <VillageCard
            village={village}
            key={index}
            lang={lang}
            edition={edition}
          />
        ))}
      </div>
    </div>
  );
};

const VillageCard = ({ village, lang, edition }) => {
  const { name, iconURL, categories, description } = village;
  return (
    <div className="village-card">
      <div className="village-card-data">
        <img className="village-up" src={VillageUp} alt="village-up" />
        <div className="village-categories">
          {categories.map((category, index) => (
            <CategoryPin
              category={category}
              key={index}
              lang={lang}
              edition={edition}
            />
          ))}
        </div>
        <img className="village-icon" src={iconURL} alt="village" />
        <p className="village-title">{name.toUpperCase()}</p>

        <p className="village-desc">{description[lang]}</p>
      </div>
      <div className="village-links">
        <div className="village-website">
          <img src={Link} alt="village" />
          <a href={village.link} target="_blank" rel="noreferrer">
            {edition.translations["innovation_villages/see_more"][lang]}
          </a>
        </div>
      </div>
    </div>
  );
};

const CategoryPin = ({ category, edition, lang }) => {
  return (
    <div className="category-pin" style={{ backgroundColor: category.color }}>
      <p>{category.title[lang].toUpperCase()}</p>
    </div>
  );
};

export { VillagePage, VillageCard };
