import React, { useState, useEffect, useContext } from "react";
import { Arrow } from "../../assets";
import { useSearchParams } from "react-router-dom";
import { map_A_Map, multiPartitionBy, containsAll } from "../../utils/utils";
import { VillageCard } from "../villages/VillagePage";
import CountUp from "react-countup";
import "./Spring50Page.css";
import "./Spring50Page_Resp.css";
import { BackendClient } from "../../utils/api_request";
import { EditionContext, LangContext } from "../../App";
import { MarkdownText } from "../../components/markdown/MarkdownText";

let currentParrain = 0;

const Spring50Page = () => {
  const [parrains, setParrains] = useState();
  const [villages, setVillages] = useState();
  const [visibleVillages, setVisibleVillages] = useState();
  const [categoryCount, setCategoryCount] = useState();
  const { edition } = useContext(EditionContext);
  const { lang } = useContext(LangContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [thematicVillages, setThematicVillages] = useState();
  useEffect(() => {
    setParrains(edition.parrains);
    BackendClient.post("/thematic_villages", { edition: edition._id })
      .then((data) => data.data)
      .then((data) => {
        const result = new Map();
        data.data.forEach((item) => result.set(item._id, item));
        setThematicVillages(result);
      });
    BackendClient.post("/villages", { edition: edition._id })
      .then((data) => data.data)
      .then((data) => {
        const toValidate = edition.spring50_page_required.map(
          (item) => item._id
        );
        const temp = data.data.filter((item) =>
          containsAll(
            toValidate,
            item.categories.map((category) => category._id)
          )
        );
        setVillages(temp);
        setVisibleVillages(temp);
        setCategoryCount(
          map_A_Map(
            multiPartitionBy(temp, (village) =>
              village.categories.map((category) => category._id)
            ),
            (arr) => arr.length
          )
        );
      });
  }, [edition._id, edition.parrains, edition.spring50_page_required]);
  useEffect(() => {
    if (!villages) {
      return;
    }
    if (!searchParams.get("category")) {
      setVisibleVillages(villages);
      return;
    }
    setVisibleVillages(
      villages.filter((village) =>
        village.categories
          .map((item) => item._id)
          .includes(searchParams.get("category"))
      )
    );
  }, [searchParams, villages]);
  if (!villages || !categoryCount)
    return (
      <div className="village-page">
        <p>Loading</p>
      </div>
    );
  return (
    <div className="spring50-page">
      <p className="spring50-title">
        {edition.translations["spring50/title"][lang]}
      </p>
      <p>
        {edition.translations["spring50/money_raised"][lang]}:{" "}
        <CountUp
          start={0}
          end={edition.money_raised}
          duration={2.75}
          useEasing={true}
          useGrouping={true}
          separator=" "
          decimals={0}
          suffix={"€"}
        />
      </p>
      <div className="spring50-showoff">
        <MarkdownText>
          {edition.translations["spring50/text1"][lang]}
        </MarkdownText>
        <div className="spring50-parrains">
          <div className="spring50-parrains-buttons">
            <button
              id="parrains-previous"
              className="blocked"
              onClick={() => {
                parrainMoved(-1);
              }}
            >
              <Arrow color="var(--blue)" size="3rem" />
            </button>
            <button
              id="parrains-next"
              onClick={() => {
                parrainMoved(+1);
              }}
            >
              <Arrow color="var(--blue)" size="3rem" />
            </button>
          </div>
          {parrains.map((parrain, index) => (
            <Parrain
              parrain={parrain}
              key={index}
              id={index - currentParrain}
              lang={lang}
            />
          ))}
        </div>
        <MarkdownText>
          {edition.translations["spring50/text2"][lang]}
        </MarkdownText>
      </div>
      <select
        onChange={(event) => {
          if (event.target.selectedIndex === 0) {
            setSearchParams({});
            return;
          }
          setSearchParams({ category: event.target.value });
        }}
      >
        <option>{edition.translations["spring50/all_villages"][lang]}</option>
        {[...categoryCount.keys()]
          .map((key) => thematicVillages.get(key))
          .map((category, index) => (
            <option key={index} value={category._id}>
              {category.title[lang]} ({categoryCount.get(category._id)})
            </option>
          ))}
      </select>
      <div className="village-page-villages">
        {visibleVillages.map((village, index) => (
          <VillageCard
            village={village}
            key={index}
            edition={edition}
            lang={lang}
          />
        ))}
      </div>
    </div>
  );
};

const Parrain = ({ parrain, id, lang }) => {
  let { name, bio, quote, pictureURL } = parrain;
  return (
    <div
      className={"parrain-card" + (id === 0 ? " parrain-card-selected" : "")}
      style={{ transform: `translate(${10 * id}%)`, zIndex: 10 - Math.abs(id) }}
    >
      <img src={pictureURL} alt="parrain" />
      <div className="parrain-card-text">
        <p>{name.toUpperCase()}</p>
        <p>{bio[lang]}</p>
        <div />
        <p>
          <i>{quote[lang]}</i>
        </p>
      </div>
    </div>
  );
};

const parrainMoved = (delta) => {
  currentParrain += delta;
  const cards = document.getElementsByClassName("parrain-card");
  const previous = document.getElementById("parrains-previous");
  const next = document.getElementById("parrains-next");
  previous.classList.remove("blocked");
  next.classList.remove("blocked");

  if (currentParrain <= 0) {
    currentParrain = 0;
    previous.classList.add("blocked");
  }

  if (currentParrain >= cards.length - 1) {
    currentParrain = cards.length - 1;
    next.classList.add("blocked");
  }

  for (let i = 0; i < cards.length; i++) {
    cards[i].classList.remove("parrain-card-selected");
    cards[i].style.transform = `translate(${10 * (i - currentParrain)}%)`;
    cards[i].style.zIndex = 10 - Math.abs(i - currentParrain);
  }
  cards[currentParrain].classList.add("parrain-card-selected");
};

export { Spring50Page };
