import React, { useContext } from 'react'
import { EditionContext, LangContext } from '../../App'

import './PressPage.css'
import { ClassicButton } from '../../components/buttons/Buttons'

const PressPage = () => {

    const { edition } = useContext(EditionContext)
    const { lang } = useContext(LangContext)

    return (
        <div className='press-page'>
            <p className="press-title">{edition.translations["press/title"][lang]}</p>
            <div className="press-page-contacts">
                {edition.contacts_press.map((contact, index) => <div className="press-page-contact">

                    <p><span>{contact.name}</span> - <span>{contact.position[lang]}</span></p>
                    <a href={`mailto:${contact.mail}`}><p>{contact.email}</p></a>
                </div>)}
            </div>
            <ClassicButton text={edition.translations["press/ask_for_accreditation"][lang]} onClick={() => { window.open("mailto:presse@paris-saclay-spring.com") }} />
        </div>
    )
}

export { PressPage }