import React from 'react'

export const Group1 = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1699px" height="1146px" viewBox="0 0 1699 1146"><title>Group</title>
        <g id="Run-01" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="D1440/010_02-09_Homepage" transform="translate(44.000000, -979.000000)" strokeWidth="0.5">
                <g id="Group" transform="translate(-43.000000, 980.000000)">
                    <circle id="Oval" stroke="#00F0C8" cx="601" cy="934" r="28">
                    </circle>
                    <circle id="Oval" stroke="#008BC4" cx="487.5" cy="844.5" r="89.5">
                    </circle>
                    <circle id="Oval" stroke="#00F0C8" cx="1441" cy="256" r="256">
                    </circle>
                    <circle id="Oval" stroke="#FF6E00" cx="179" cy="965" r="179">
                    </circle>
                    <circle id="Oval" stroke="#563943" cx="1185" cy="177" r="22">
                    </circle>
                </g>
            </g>
        </g>
    </svg>)
}