import React from "react";

import "./Buttons.css";

export const HighlightableText = ({
  text,
  onClick = () => {},
  selected = false,
}) => {
  return (
    <div
      className={
        "highlightable-text" + (selected ? " highlightable-text-selected" : "")
      }
      onClick={onClick}
    >
      <p>{text}</p>
    </div>
  );
};

export const ClassicButton = ({ text, onClick = () => {} }) => {
  return (
    <div className="button-classic" onClick={onClick}>
      <p>{text}</p>
    </div>
  );
};
