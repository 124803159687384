import React, { useContext, useMemo, useState } from "react";
import {
  GoogleMap,
  MarkerF,
  useLoadScript,
  InfoWindowF,
} from "@react-google-maps/api";
import { ClassicButton } from "../../components/buttons/Buttons";
import { Arrow } from "../../assets";
import "./InfosPage.css";
import "./InfosPage_Resp.css";
import { EditionContext, LangContext } from "../../App";

import { MarkdownText } from "../../components/markdown/MarkdownText";

const InfosPage = () => {
  const { edition } = useContext(EditionContext);
  const { lang } = useContext(LangContext);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const center = useMemo(
    () => ({
      lat: parseFloat(edition.map_lat),
      lng: parseFloat(edition.map_long),
    }),
    [edition.map_lat, edition.map_long]
  );
  const listeners = [];
  return (
    <div className="infopage">
      <p id="contacts">
        {edition.translations["infos/contacts"][lang].toUpperCase()}
      </p>
      <div className="infopage-contacts">
        {edition.contacts.map((contact, index) => (
          <ContactCard contact={contact} key={index} lang={lang} />
        ))}
      </div>
      <p>{edition.translations["infos/usefull_infos"][lang].toUpperCase()}</p>
      <div className="infopage-mainplan">
        <MarkdownText>{edition.translations["infos/desc"][lang]}</MarkdownText>
      </div>
      <ClassicButton
        text={edition.translations["infos/download_recap"][lang]}
        onClick={() => {
          window.open(edition.info_link);
        }}
      />
      <p>
        {edition.translations["infos/points_of_information"][
          lang
        ].toUpperCase()}
      </p>
      <div className="infopage-map">
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap
            mapContainerClassName="infopage-map-container"
            center={center}
            zoom={15}
            yesIWantToUseGoogleMapApiInternals
            options={{
              styles: [
                {
                  elementType: "labels",
                  featureType: "poi.business",
                  stylers: [{ visibility: "off" }],
                },
              ],
            }}
            onClick={() => {
              console.log(listeners);
              for (let i = 0; i < listeners.length; i++) {
                listeners[i](false);
              }
            }}
          >
            {edition.categorized_addresses
              .map((cat) => cat.addresses)
              .flat(1)
              .map((addr, index) => (
                <CustomMarker
                  data={addr}
                  key={index}
                  listeners={listeners}
                  lang={lang}
                />
              ))}
          </GoogleMap>
        )}
      </div>
      <div className="infopage-marker-cards">
        {edition.categorized_addresses.map((category, index1) => (
          <div className="infopage-marker-cards-category" key={index1}>
            <p>{category.title["fr"].toUpperCase()}</p>
            <div>
              {category.addresses.map((marker, index2) => (
                <MarkerInfoCard marker={marker} key={index2} lang={lang} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ContactCard = ({ contact, lang }) => {
  return (
    <div className="infopage-contact">
      <img src={contact.pictureURL} alt="contact img" />
      <p>{contact.name} </p>
      <p>({contact.langs.map((item) => item.name[lang]).join()})</p>
      <p>{contact.position[lang]}</p>
      <a href={`mailto:${contact.email}`}>
        <p>{contact.email}</p>
      </a>
    </div>
  );
};

const CustomMarker = ({ data, listeners, lang }) => {
  const [isOpen, setIsOpen] = useState(false);
  listeners.push(setIsOpen);
  console.log(data);
  return (
    <MarkerF
      position={{
        lat: parseFloat(data.latitude),
        lng: parseFloat(data.longitude),
      }}
      onClick={() => {
        for (let i = 0; i < listeners.length; i++) {
          listeners[i](false);
        }
        setIsOpen(true);
      }}
      icon={{
        url: `http://maps.google.com/mapfiles/ms/icons/red-dot.png`,
        scaledSize: new window.google.maps.Size(40, 40),
      }}
    >
      {isOpen ? (
        <InfoWindowF
          position={data.position}
          onCloseClick={() => {
            setIsOpen(false);
          }}
        >
          <div className="spring50-info-window">
            <h1>{data.title[lang]}</h1>
            <p>{data.address1}</p>
            <p>{data.address2}</p>
            <a
              href={`https://www.google.com/maps/search/${data.title[
                "fr"
              ].replaceAll(" ", "+")}/@${data.latitude},${data.longitude},15z`}
              target="_blank"
              rel="noreferrer"
            >
              Ouvrir dans Google Maps
            </a>
          </div>
        </InfoWindowF>
      ) : null}
    </MarkerF>
  );
};

const MarkerInfoCard = ({ marker, lang }) => {
  return (
    <div
      className="infopage-marker-card"
      onClick={(event) => {
        const markerCards = document.getElementsByClassName(
          "infopage-marker-card"
        );
        for (let i = 0; i < markerCards.length; i++) {
          if (markerCards[i] !== event.currentTarget)
            markerCards[i].classList.remove("infopage-marker-card-open");
        }
        if (
          !event.currentTarget.classList.contains("infopage-marker-card-open")
        ) {
          event.currentTarget.classList.add("infopage-marker-card-open");
        } else {
          event.currentTarget.classList.remove("infopage-marker-card-open");
        }
      }}
    >
      <div className="infopage-marker-card-flag">
        <Arrow color="var(--yellow)" size="1.1rem" />
        <p>{marker.title[lang]}</p>
      </div>
      <div className="infopage-marker-card-content">
        <p>
          {marker.address1},{marker.address2}
        </p>
        <a
          href={`https://www.google.com/maps/search/${marker.title[
            "fr"
          ].replaceAll(" ", "+")}/@${marker.latitude},${marker.longitude},15z`}
          target="_blank"
          rel="noreferrer"
        >
          Ouvrir dans Google Maps
        </a>
      </div>
    </div>
  );
};

export { InfosPage };
