import React, { useContext } from "react";
import { EditionContext, LangContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { Logo, LogoSub } from "../../components/logo/Logo";
import {
  Arrow,
  Divider,
  Float1,
  Float2,
  Float3,
  LogoHorizontal,
} from "../../assets";
import { BigDivider } from "../../assets";
import { Group1 } from "../../components/props/Props";
import { map_A_Map, partitionBy } from "../../utils/utils";
import "./HomePage.css";
import "./HomePage_Resp.css";
import "./HomePage2.css";
import { ClassicButton } from "../../components/buttons/Buttons";
import CountUp from "react-countup";
import moment from "moment";

var currentNews = 0;
var currentInfo = window.screen.width > 900 ? 1 : 0;

const HomePage = () => {
  const navigate = useNavigate();
  const { lang } = useContext(LangContext);
  moment.locale(lang);
  const { edition } = useContext(EditionContext);
  return (
    <div className="homepage">
      <div className="homepage-logos">
        <div>
          <Logo />
        </div>
        <div>
          <LogoSub />
        </div>
      </div>
      <div className="homepage-news">
        {edition.news.map((item, key) => (
          <NewsItem
            item={item}
            id={key}
            key={key}
            lang={lang}
            edition={edition}
          />
        ))}
      </div>
      <div className="homepage-news-buttons">
        <button
          id="news-previous"
          onClick={() => {
            createMovementForNews(-1, edition.news);
          }}
        >
          <Arrow color="white" size="3rem" />
        </button>
        <button
          id="news-next"
          onClick={() => {
            createMovementForNews(+1, edition.news);
          }}
        >
          <Arrow color="white" size="3rem" />
        </button>
      </div>
      <img src={BigDivider} alt="bigdivider" />
      <div className="spring-presentation">
        <Group1 />
        <Group1 />
        <img id="float1" src={Float1} alt="float1" />
        <img id="float2" src={Float2} alt="float2" />
        <img id="float3" src={Float3} alt="float3" />
        <img id="float4" src={Float3} alt="float4" />
        <img id="float5" src={Float3} alt="float5" />
        <div className="spring-presentation-title">
          <p>
            {edition.translations["homepage/presentation/title"][
              lang
            ].toUpperCase()}
          </p>
          <p>
            {edition.translations["homepage/presentation/slogan"][
              lang
            ].toUpperCase()}
          </p>
        </div>
        <img className="presentation-logo" src={LogoHorizontal} alt="logo" />
        <img src={Divider} alt="divider" className="homepage-divider" />
        <p>
          {edition.translations["homepage/presentation/text1"][lang]}
          <br />
          <br />
          {edition.translations["homepage/presentation/text2"][lang]}
        </p>
        <ClassicButton
          text={edition.translations["homepage/presentation/program"][lang]}
          onClick={() => {
            navigate("/events");
          }}
        />

        <div className="spring-newsletter">
          <p>
            {edition.translations["homepage/newsletter/title"][
              lang
            ].toUpperCase()}
          </p>
          <p>{edition.translations["homepage/newsletter/desc"][lang]}</p>
          <ClassicButton
            text={edition.translations["homepage/newsletter/subscribe"][lang]}
            onClick={() => {
              window.open(edition.newsletter_link[lang], "_blank");
            }}
          />
        </div>
      </div>
      <img src={BigDivider} alt="bigdivider" />
      <div className="homepage-infos">
        <p>
          {edition.translations["homepage/info_cards/in_brief"][
            lang
          ].toUpperCase()}
        </p>
        <div className="homepage-info-cards">
          {edition.info_cards.map((info, index) => (
            <InfoCard
              info={info}
              key={index}
              id={index - currentInfo}
              lang={lang}
            />
          ))}
        </div>
        <div className="homepage-info-cards-buttons">
          <button
            id="infos-previous"
            onClick={() => {
              createMovementForInfos(-1, edition.info_cards);
            }}
          >
            <Arrow color="var(--blue)" size="3rem" />
          </button>
          <button
            id="infos-next"
            onClick={() => {
              createMovementForInfos(+1, edition.info_cards);
            }}
          >
            <Arrow color="var(--blue)" size="3rem" />
          </button>
        </div>
      </div>
      <div className="homepage-global-partners">
        <p>{edition.translations["homepage/co_ambassadors"][lang]}</p>
        <div className="homepage-co-organisators">
          {edition.co_ambassadors.map((image, index) => (
            <img src={image.iconURL} alt="co organisator" key={index} />
          ))}
        </div>
        <p>{edition.translations["homepage/ambassadors"][lang]}</p>
        <div className="homepage-ambassadors">
          {edition.ambassadors.map((image, index) => (
            <img src={image.iconURL} alt="ambassador" key={index} />
          ))}
        </div>
        <p>{edition.translations["homepage/partners"][lang]}</p>
        <div className="homepage-partners">
          {edition.partners.map((image, index) => (
            <img src={image.iconURL} alt="ambassador" key={index} />
          ))}
        </div>
        {edition.partners_mobility.length > 0 ? (
          <React.Fragment>
            <p>{edition.translations["homepage/partners_mobility"][lang]}</p>
            <div className="homepage-partners">
              {edition.partners_mobility.map((image, index) => (
                <img src={image.iconURL} alt="mobility" key={index} />
              ))}
            </div>
          </React.Fragment>
        ) : null}
        {edition.partners_media.length > 0 ? (
          <React.Fragment>
            <p>{edition.translations["homepage/partners_media"][lang]}</p>
            <div className="homepage-partners">
              {edition.partners_media.map((image, index) => (
                <img src={image.iconURL} alt="partners-media" key={index} />
              ))}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

const NewsItem = ({ item, id, lang, edition }) => {
  return (
    <div
      className="homepage-news-item"
      style={{ transform: `translateX(${100 * id}%)` }}
    >
      <img src={item.pictureURL} alt="temp_news" />
      <div className="homepage-news-item-data">
        <div className="homepage-news-title">
          <p>{summarizeDates(edition.dates).toUpperCase()}</p>
        </div>
        <div className="homepage-news-item-loc">
          <p>{item.title[lang].toUpperCase()}</p>
        </div>
        <p>{item.desc[lang]}</p>
        <a
          className="homepage-news-more"
          href={item.link}
          target="_blank"
          rel="noreferrer"
        >
          <p>{edition.translations["homepage/see_more"][lang]}</p> <Arrow />
        </a>
      </div>
    </div>
  );
};

const InfoCard = ({ info, id, lang }) => {
  let decorator = info.decorator ?? "";
  return (
    <div
      className="homepage-info-card"
      style={{ transform: `translateX(${110 * id}%)` }}
    >
      <p>
        <CountUp
          start={0}
          end={info.main}
          duration={2.75}
          useEasing={true}
          useGrouping={true}
          separator=" "
          decimals={0}
          suffix={decorator}
        />
      </p>
      <p>{info.bold[lang].toUpperCase()}</p>
      <p>{info.sub[lang]}</p>
    </div>
  );
};

const newsMoved = () => {
  const news = document.getElementsByClassName("homepage-news-item");
  for (let i = 0; i < news.length; i++) {
    news[i].style.transform = `translateX(${100 * (i - currentNews)}%)`;
  }
};

const createMovementForNews = (delta, news) => {
  currentNews += delta;
  document.getElementById("news-previous").classList.remove("blocked");
  document.getElementById("news-next").classList.remove("blocked");
  if (currentNews < 0) {
    currentNews = news.length - 1;
  }
  if (currentNews > news.length - 1) {
    currentNews = 0;
  }
  newsMoved();
};

const infosMoved = () => {
  const news = document.getElementsByClassName("homepage-info-card");
  for (let i = 0; i < news.length; i++) {
    news[i].style.transform = `translateX(${110 * (i - currentInfo)}%)`;
  }
};

const createMovementForInfos = (delta, infoCards) => {
  currentInfo += delta;
  document.getElementById("infos-previous").classList.remove("blocked");
  document.getElementById("infos-next").classList.remove("blocked");
  let margin = window.screen.width > 900 ? 1 : 0;
  if (currentInfo <= margin) {
    currentInfo = margin;
    document.getElementById("infos-previous").classList.add("blocked");
  }
  if (currentInfo >= infoCards.length - 1 - margin) {
    currentInfo = infoCards.length - 1 - margin;
    document.getElementById("infos-next").classList.add("blocked");
  }
  infosMoved();
};

function summarizeDates(dates) {
  const order = dates.sort();
  const months = partitionBy(order, (date) => date.substring(0, 7));
  const result = map_A_Map(months, (month) => {
    const min = month.reduce((min, c) => (c < min ? c : min));
    const max = month.reduce((max, c) => (c > max ? c : max));
    return `${new Date(min).getDate()} - ${new Date(max).getDate()} ${moment(
      new Date(min)
    ).format("MMMM YYYY")}`;
  });
  return Array.from(result.values()).join(", ");
}

export { HomePage };
