import React from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import "./MarkdownText.css";

const image = (props) => {
  const { alt, ...rest } = props;
  return (
    <div className="markdown-image">
      <img {...rest} alt={alt} />
    </div>
  );
};

const paragraph = (props) => {
  const { children } = props;
  console.log(children);

  if (children?.props?.src) {
    return (
      <div className="markdown-image">
        <img src={children.props.src} alt="markdown" />
      </div>
    );
  }
  return <p>{children}</p>;
};

const MarkdownText = (props) => {
  return (
    <div className="markdown-text">
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{ img: image, p: paragraph }}
      >
        {props.children}
      </Markdown>
    </div>
  );
};

export { MarkdownText };
