import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Divider, Logo_Spring, CustomerService, Menu } from "../../assets";
import { ClassicButton, HighlightableText } from "../buttons/Buttons";
import { EditionContext, LangContext } from "../../App";
import moment from "moment";
import "./NavBar.css";
import "./NavBar_Resp.css";
import { partitionBy, map_A_Map } from "../../utils/utils";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang, setLang } = useContext(LangContext);
  moment.locale(lang);
  const { edition } = useContext(EditionContext);
  const pages = [
    {
      name: edition.translations["navbar/program"][lang] + " " + edition.year,
      target: "/events",
    },
    {
      name: edition.translations["navbar/innovation_villages"][lang],
      target: "/villages",
    },
    {
      name: edition.translations["navbar/spring50"][lang],
      target: "/spring50",
    },
    {
      name: edition.translations["navbar/innovation_circuits"][lang],
      target: "/circuits",
    },
    {
      name: edition.translations["navbar/previous_editions"][lang],
      target: "/previous_editions",
    },
    { name: edition.translations["navbar/infos"][lang], target: "/infos" },
  ];

  summarizeDates(edition.dates);

  return (
    <div className="navbar">
      <div className="contact-pin">
        <a href="/infos#contacts">
          <img src={CustomerService} alt="contacts" />
        </a>
      </div>
      <div className="navbar-content">
        <div className="navbar-left">
          <img
            src={Logo_Spring}
            alt="spring"
            onClick={() => {
              navigate("/");
            }}
          />
          <img
            src={Menu}
            alt="temp"
            onClick={() => {
              const pages = document.getElementsByClassName("navbar-pages")[0];
              if (pages.classList.contains("deployed")) {
                pages.classList.remove("deployed");
              } else {
                pages.classList.add("deployed");
              }
            }}
          />
          <div className="navbar-pages">
            {pages.map((page, index) => (
              <HighlightableText
                text={page.name.toUpperCase()}
                onClick={() => {
                  const pages =
                    document.getElementsByClassName("navbar-pages")[0];
                  pages.classList.remove("deployed");
                  navigate(page.target);
                }}
                key={index}
                selected={location.pathname.startsWith(page.target)}
              />
            ))}
          </div>
        </div>
        <div className="navbar-right">
          <p>{summarizeDates(edition.dates)}</p>
          <p
            className={lang === "en" ? "navbar-selected" : ""}
            onClick={() => setLang("en")}
          >
            EN
          </p>
          <p
            className={lang === "fr" ? "navbar-selected" : ""}
            onClick={() => setLang("fr")}
          >
            FR
          </p>
          <ClassicButton
            text={edition.translations["navbar/signup"][lang]}
            onClick={() => {
              window.open(edition.login_link[lang], "_blank");
            }}
          />
        </div>
      </div>
      <img src={Divider} alt="divider" />
    </div>
  );
};

function summarizeDates(dates) {
  const order = dates.sort();
  const months = partitionBy(order, (date) => date.substring(0, 7));
  const result = map_A_Map(months, (month) => {
    const min = month.reduce((min, c) => (c < min ? c : min));
    const max = month.reduce((max, c) => (c > max ? c : max));
    if (min !== max)
      return `${new Date(min).getDate()} - ${new Date(max).getDate()} ${moment(
        new Date(min)
      ).format("MMMM YYYY")}`;
    return `${new Date(min).getDate()} ${moment(new Date(min)).format(
      "MMMM YYYY"
    )}`;
  });
  return Array.from(result.values()).join(", ");
}

export { NavBar };
