import React, { useContext } from 'react'
import { FooterCircle, FooterStripes, Twitter, LinkedIn } from '../../assets'
import { EditionContext, LangContext } from '../../App'
import './Footer.css'
import './Footer_Resp.css'

const social_medias = {
    "twitter": Twitter,
    "linkedin": LinkedIn
}

const Footer = () => {
    const { lang } = useContext(LangContext)
    const { edition } = useContext(EditionContext)
    return (
        <div className="footer">
            <img src={FooterStripes} alt='stripes' />
            <img src={FooterCircle} alt='circle' />
            <div className="footer-infos">
                <div className="footer-left">
                    <p>{edition.translations["footer/follow"][lang].toUpperCase()}</p>
                    {edition.social_medias.map((media, index) =>
                        <a href={media.link} target="_blank" rel="noreferrer" key={index}>
                            <img src={social_medias[media.name]} alt="linkedin" />
                        </a>)}
                </div>
                <div className='footer-right'>
                    <a href="press"><p>{edition.translations["footer/press_space"][lang]}</p></a>
                    <a href="legal"><p>{edition.translations["footer/legal_mentions"][lang]}</p></a>
                    {/* <a href="website-plan"><p>{Langs[lang].footer.plan}</p></a> */}
                    <p>{"Copyright © 2003 - 2021. All rights reserved."}</p>
                </div>
            </div>
        </div>
    )
}

export { Footer }