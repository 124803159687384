import React, { createContext, useState, useEffect, useContext } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { NavBar } from "./components/NavBar/NavBar";
import { Footer } from "./components/footer/Footer";
import { BackendClient } from "./utils/api_request";
import * as Pages from "./pages";
import "./App.css";
import moment from "moment-timezone";

export const LangContext = createContext("en");
export const EditionContext = createContext();

function App() {
  const [lang, setLang] = useState("en");
  const [edition, setEdition] = useState();
  moment.tz.setDefault("Europe/London");
  return (
    <div>
      <LangContext.Provider value={{ lang, setLang }}>
        <EditionContext.Provider value={{ edition, setEdition }}>
          <BrowserRouter>
            <AppContent />
          </BrowserRouter>
        </EditionContext.Provider>
      </LangContext.Provider>
    </div>
  );
}

function AppContent() {
  const [searchParams] = useSearchParams();
  const { edition, setEdition } = useContext(EditionContext);
  useEffect(() => {
    BackendClient.post("/editions", {
      year: searchParams.get("edition") ?? null,
    }).then((data) => setEdition(data.data));
  }, [searchParams, setEdition]);
  if (!edition) return <p>Cette édition n'existe pas!</p>;
  return (
    <React.Fragment>
      <NavBar />
      <Routes>
        <Route path="/" element={<Pages.HomePage />} />
        <Route path="/events" element={<Pages.EventPage />} />
        <Route path="/villages" element={<Pages.VillagePage />} />
        <Route path="/circuits" element={<Pages.CircuitPage />} />
        <Route path="/spring50" element={<Pages.Spring50Page />} />
        <Route path="/infos" element={<Pages.InfosPage />} />
        <Route path="/previous_editions" element={<Pages.PreviousPage />} />
        <Route path="/press" element={<Pages.PressPage />} />
        <Route path="/login" element={<Pages.LoginPage />} />
        <Route path="/legal" element={<Pages.LegalMentionPage />} />
      </Routes>
      <Footer />
    </React.Fragment>
  );
}

export default App;
