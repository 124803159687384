import React, { useContext } from "react";
import { LangContext, EditionContext } from "../../App";
import { MarkdownText } from "../../components/markdown/MarkdownText";

import "./LoginPage.css";

const LoginPage = () => {
  const { lang } = useContext(LangContext);
  const { edition } = useContext(EditionContext);
  return (
    <div className="login-page">
      <MarkdownText>{edition.translations["login"][lang]}</MarkdownText>
    </div>
  );
};

export { LoginPage };
