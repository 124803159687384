export const partitionBy = (target, extractor) => {
    if (!target) return new Map()
    const res = new Map()
    for (let i = 0; i < target.length; i++) {
        const key = extractor(target[i]);
        let list = res.get(key);
        if (!list) {
            list = []
            res.set(key, list)
        }
        list.push(target[i])
    }
    return res;
}

export const multiPartitionBy = (target, extractor, defaultMap = new Map()) => {
    if (!target) return new Map()
    const res = defaultMap
    for (let i = 0; i < target.length; i++) {
        const keys = extractor(target[i])
        for (let j = 0; j < keys.length; j++) {
            const key = keys[j];
            let list = res.get(key);
            if (!list) {
                list = []
                res.set(key, list)
            }
            list.push(target[i])
        }
    }
    return res;
}

export const map_A_Map = (target, func) => {
    if (!target) return new Map();
    const res = new Map();
    for (let [key, value] of target.entries()) {
        res.set(key, func(value))
    }
    return res;
}

export const containsAll = (arr1, arr2) => {
    return arr1.every(v => arr2.includes(v))
}