import React, { useContext, useState, useEffect } from "react";
import { EditionContext, LangContext } from "../../App";
import { CircuitUp } from "../../assets";
import "./CircuitPage.css";
import "./CircuitPage_Resp.css";
import { BackendClient } from "../../utils/api_request";
import { MarkdownText } from "../../components/markdown/MarkdownText";

const CircuitPage = () => {
  const { lang } = useContext(LangContext);
  const { edition } = useContext(EditionContext);

  const [circuits, setCircuits] = useState();
  useEffect(() => {
    BackendClient.post("/innovation_circuits", { edition: edition._id })
      .then((data) => data.data)
      .then((data) => setCircuits(data.data));
  }, [edition._id]);
  return (
    <div className="circuit-page">
      <p className="circuit-page-title">
        {edition.translations["innovation_circuits/title"][lang].toUpperCase()}
      </p>
      <div className="circuit-page-showoff">
        <MarkdownText>
          {edition.translations["innovation_circuits/text"][lang]}
        </MarkdownText>
      </div>

      {circuits ? (
        <div className="circuit-page-circuits">
          {circuits.map((circuit, index) => (
            <CircuitCard
              circuit={circuit}
              key={index}
              lang={lang}
              edition={edition}
            />
          ))}
        </div>
      ) : (
        <p>Loading</p>
      )}
    </div>
  );
};

const CircuitCard = ({ circuit, lang, edition }) => {
  console.log(circuit);
  return (
    <div className="circuit-card">
      <img className="circuit-up" src={CircuitUp} alt="village-up" />
      <div>
        <div className="circuit-card-content">
          <img src={circuit.pictureURL} alt="circuit img" />
          <p>{circuit.title[lang].toUpperCase()}</p>
          <div className="circuit-card-sub">
            <p>{circuit.address}</p>
            <a href={circuit["link"]} target="_blank" rel="noreferrer">
              <p>
                {
                  edition.translations["innovation_circuits/in_google_maps"][
                    lang
                  ]
                }
              </p>
            </a>
            <p>{circuit.desc[lang]}</p>
          </div>
        </div>
        {/* <div className="circuit-card-website">
          <img src={Link} alt="inscription" />
          <a href={circuit["link"]} target="_blank" rel="noreferrer">
            <p>{edition.translations["innovation_circuits/see_more"][lang]}</p>
          </a>
        </div> */}
      </div>
    </div>
  );
};

export { CircuitPage };
