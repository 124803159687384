

export { default as Logo_Spring } from './logo_spring.png'
export { default as Divider } from './divider.png'
export { default as EventLeft } from './event_left.png'
export { default as BigDivider } from './big-divider.png'
export { default as VillageUp } from './village-up.png'
export { default as Link } from './link.png'
export { default as Spring50 } from './spring50.png'
export { default as TempNews } from './temp_news.png'
export { default as LogoHorizontal } from './logo_horizontal.png'
export { default as Float1 } from './float1.png'
export { default as Float2 } from './float2.png'
export { default as Float3 } from './float3.png'
export { default as ActuEssone } from './actu_essone.png'
export { default as MainPlan } from './main_plan.png'
export { default as CircuitUp } from './circuit-up.png'
export { default as FooterCircle } from './footer-circle.png'
export { default as FooterStripes } from './footer-stripes.png'
export { default as Twitter } from './twitter.png'
export { default as LinkedIn } from './linkedin.png'
export { default as CustomerService } from './customer-service.png'
export {default as Menu}from './menu.png'

export { default as Co_Organisators } from './co_organisator'
export { default as Ambassadors } from './ambassador'
export { default as Partners } from './partners'

export const Arrow = ({ color = "#000000", size = "40px" }) => {
    return (<svg width={size} height={size} viewBox="0 0 512 512" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M393.344 225.254L179.413 12.5131C175.423 8.54595 170.687 5.39906 165.475 3.25206C160.263 1.10506 154.676 0 149.034 0C137.64 0 126.713 4.5011 118.656 12.5131C114.667 16.4802 111.502 21.1899 109.343 26.3732C107.184 31.5565 106.073 37.112 106.073 42.7224C106.073 54.053 110.599 64.9196 118.656 72.9316L302.637 255.464L118.656 437.996C114.646 441.951 111.463 446.657 109.291 451.842C107.118 457.027 106 462.588 106 468.205C106 473.822 107.118 479.383 109.291 484.568C111.463 489.753 114.646 494.459 118.656 498.414C122.634 502.402 127.366 505.568 132.58 507.728C137.794 509.888 143.386 511 149.034 511C154.683 511 160.275 509.888 165.489 507.728C170.703 505.568 175.435 502.402 179.413 498.414L393.344 285.673C397.354 281.718 400.537 277.012 402.709 271.827C404.882 266.642 406 261.081 406 255.464C406 249.847 404.882 244.286 402.709 239.101C400.537 233.916 397.354 229.21 393.344 225.254Z" fill={color} />
    </svg>)
}