import React from 'react'
import './Logo.css'
import './LogoSub.css'

const Logo = () => {
  return (

    <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1801.05 419.33">
      <defs>
        <clipPath id="clippath">
          <path id="Masque" className="cls-5" d="m806.96,307.47c53.58,53.58,140.46,53.58,194.04,0,53.58-53.58,53.58-140.46,0-194.04-53.58-53.59-140.46-53.59-194.04,0-53.58,53.58-53.58,140.46,0,194.04Z" />
        </clipPath>
      </defs>
      <g id="Forme">
        <g className="cls-12">
          <g>
            <rect id="f1" className="cls-6" x="-1209.56" width="3736.72" height="42.44" transform="translate(1317.6 42.44) rotate(180)" />
            <rect id="f2" className="cls-3" x="-994.37" y="293.62" width="3736.72" height="42.43" transform="translate(1747.98 629.67) rotate(180)" />
            <rect id="f3" className="cls-2" x="-994.37" y="334.99" width="3736.72" height="42.43" transform="translate(1747.98 712.42) rotate(180)" />
            <rect id="f4" className="cls-9" x="-994.37" y="376.89" width="3736.72" height="42.43" transform="translate(1747.98 796.22) rotate(180)" />
            <rect id="f5" className="cls-9" x="-994.37" y="168.13" width="3736.72" height="42.43" transform="translate(1747.98 378.69) rotate(180)" />
            <rect id="f6" className="cls-11" x="-994.37" y="209.5" width="3736.72" height="42.43" transform="translate(1747.98 461.43) rotate(180)" />
            <rect id="f7" className="cls-6" x="-994.37" y="251.4" width="3736.72" height="42.44" transform="translate(1747.98 545.24) rotate(180)" />
            <rect id="f8" className="cls-3" x="-994.37" y="42.64" width="3736.72" height="42.43" transform="translate(1747.98 127.71) rotate(180)" />
            <rect id="f9" className="cls-7" x="-994.2" y="84.49" width="3736.7" height="42.43" transform="translate(1748.3 211.41) rotate(180)" />
            <rect id="f10" className="cls-10" x="-994.37" y="125.92" width="3736.72" height="42.43" transform="translate(1747.98 294.26) rotate(180)" />
            <rect id="f11" className="cls-2" x="-1100.79" y="-6.35" width="3736.72" height="42.43" transform="translate(1299.81 568.13) rotate(-135)" />
            <rect id="f12" className="cls-4" x="-1040.78" y="-66.36" width="3736.72" height="42.43" transform="translate(1444.69 508.12) rotate(-135)" />
            <rect id="f13" className="cls-1" x="-1070.78" y="-36.36" width="3736.72" height="42.43" transform="translate(1372.25 538.13) rotate(-135)" />
            <rect id="f14" className="cls-9" x="-1110.18" y="530.93" width="3736.72" height="42.43" transform="translate(1684.72 406.46) rotate(135)" />
            <rect id="f15" className="cls-4" x="-1170.2" y="470.92" width="3736.72" height="42.43" transform="translate(1539.84 346.45) rotate(135)" />
            <rect id="f16" className="cls-11" x="-1140.19" y="500.92" width="3736.72" height="42.43" transform="translate(1612.28 376.45) rotate(135)" />
          </g>
        </g>
      </g>
      <g id="Texte">
        <g>
          <path className="cls-8" d="m808.5,245.24c-3.75,0-7.31-.58-10.68-1.74-3.37-1.16-6.35-2.91-8.94-5.23l6.46-7.73c4.19,3.35,8.71,5.02,13.58,5.02,1.55,0,2.74-.25,3.56-.74.82-.5,1.22-1.2,1.22-2.12v-.12c0-.44-.1-.82-.3-1.16-.2-.34-.57-.66-1.11-.99-.54-.31-1.26-.62-2.15-.92-.9-.3-2.06-.61-3.5-.93-2.23-.52-4.31-1.08-6.22-1.7-1.91-.61-3.58-1.41-4.99-2.39-1.42-.97-2.53-2.19-3.35-3.64-.82-1.45-1.23-3.26-1.23-5.41v-.12c0-1.95.37-3.74,1.11-5.37.74-1.63,1.81-3.04,3.23-4.24,1.41-1.19,3.12-2.12,5.11-2.78,1.99-.66,4.25-.99,6.76-.99,3.59,0,6.79.47,9.6,1.42,2.81.94,5.35,2.34,7.62,4.18l-5.8,8.22c-1.91-1.35-3.88-2.38-5.89-3.08-2.01-.7-3.96-1.04-5.83-1.04-1.4,0-2.44.26-3.14.77-.7.52-1.05,1.16-1.05,1.91v.12c0,.48.11.9.33,1.25.22.36.6.69,1.14.99.54.3,1.28.6,2.21.9.94.3,2.12.61,3.56.93,2.39.52,4.55,1.12,6.49,1.82,1.93.7,3.58,1.54,4.93,2.54,1.36,1,2.39,2.2,3.11,3.61.72,1.41,1.08,3.1,1.08,5.04v.12c0,2.15-.41,4.06-1.22,5.73-.82,1.67-1.97,3.09-3.44,4.27-1.48,1.18-3.25,2.07-5.32,2.69-2.07.62-4.39.92-6.94.92Z" />
          <path className="cls-8" d="m831.05,202.66h17.94c2.63,0,5.02.33,7.18.99,2.15.66,3.99,1.61,5.5,2.87,1.52,1.26,2.68,2.8,3.5,4.64.82,1.83,1.23,3.91,1.23,6.22v.12c0,2.47-.45,4.65-1.35,6.52-.9,1.88-2.14,3.45-3.74,4.72-1.59,1.27-3.5,2.23-5.71,2.87-2.21.64-4.61.96-7.21.96h-5.74v11.96h-11.6v-41.86Zm17.1,20.81c2.07,0,3.7-.49,4.87-1.47,1.18-.98,1.76-2.32,1.76-4v-.12c0-1.8-.59-3.18-1.76-4.12-1.18-.94-2.82-1.41-4.93-1.41h-5.44v11.12h5.5Z" />
          <path className="cls-8" d="m871.29,202.66h19.79c3.23,0,5.96.4,8.19,1.2,2.23.8,4.05,1.91,5.44,3.35,2.47,2.39,3.71,5.64,3.71,9.75v.12c0,3.23-.78,5.9-2.33,8.01-1.56,2.11-3.65,3.73-6.28,4.84l9.99,14.59h-13.4l-8.43-12.68h-5.08v12.68h-11.6v-41.86Zm19.26,20.09c1.99,0,3.53-.45,4.6-1.34,1.08-.9,1.62-2.11,1.62-3.62v-.12c0-1.67-.56-2.92-1.68-3.74-1.11-.82-2.65-1.22-4.6-1.22h-7.6v10.05h7.66Z" />
          <path className="cls-8" d="m914.53,202.66h11.66v41.86h-11.66v-41.86Z" />
          <path className="cls-8" d="m933.85,202.66h10.85l17.26,22.13v-22.13h11.42v41.86h-10.19l-17.92-22.96v22.96h-11.42v-41.86Z" />
          <path className="cls-8" d="m1002.14,245.36c-3.27,0-6.29-.53-9.06-1.58-2.77-1.06-5.17-2.54-7.21-4.46-2.03-1.91-3.63-4.2-4.78-6.85-1.16-2.65-1.73-5.57-1.73-8.76v-.12c0-3.03.56-5.87,1.7-8.52,1.13-2.65,2.69-4.96,4.67-6.94,1.98-1.97,4.36-3.52,7.12-4.64,2.77-1.12,5.77-1.67,9-1.67,3.7,0,6.91.54,9.6,1.61,2.69,1.08,5.15,2.57,7.39,4.49l-6.82,8.19c-1.51-1.27-3.06-2.25-4.64-2.93-1.58-.68-3.4-1.02-5.47-1.02-1.52,0-2.92.3-4.22.9-1.29.6-2.42,1.42-3.38,2.45-.96,1.04-1.71,2.25-2.27,3.65-.56,1.39-.84,2.89-.84,4.49v.12c0,1.67.28,3.23.84,4.66.56,1.44,1.35,2.66,2.37,3.68,1.02,1.02,2.22,1.82,3.6,2.39,1.38.58,2.91.86,4.59.86,2.72,0,4.96-.58,6.72-1.73v-5.08h-8.25v-8.49h19.26v18.84c-2.23,1.83-4.87,3.37-7.9,4.6-3.03,1.24-6.46,1.86-10.28,1.86Z" />
        </g>
        <path className="cls-8" d="m788.21,196.28h5.62v-5.81h2.8c5.06,0,8.75-2.53,8.75-7.32v-.06c0-4.5-3.31-7.14-8.46-7.14h-8.71v20.34Zm5.62-10.23v-5.38h2.65c2.04,0,3.26.93,3.26,2.68v.06c0,1.6-1.22,2.65-3.24,2.65h-2.68Zm10.7,10.23h5.93l1.46-3.64h7.85l1.47,3.64h6.05l-8.66-20.48h-5.43l-8.66,20.48Zm9.04-8.02l2.29-5.78,2.28,5.78h-4.57Zm16.56,8.02h5.64v-6.16h2.46l4.1,6.16h6.51l-4.86-7.09c2.53-1.08,4.19-3.14,4.19-6.25v-.06c0-2.01-.62-3.58-1.81-4.74-1.36-1.39-3.51-2.2-6.62-2.2h-9.63v20.34Zm5.64-10.57v-4.89h3.69c1.89,0,3.05.82,3.05,2.42v.06c0,1.48-1.1,2.42-3.02,2.42h-3.72Zm16.25,10.57h5.66v-20.34h-5.66v20.34Zm18.25.35c4.94,0,8.22-2.47,8.22-6.62v-.06c0-3.81-2.9-5.32-7.59-6.34-2.82-.64-3.51-1.04-3.51-1.97v-.06c0-.73.67-1.31,2.03-1.31,1.8,0,3.84.7,5.7,2.01l2.82-3.98c-2.21-1.77-4.91-2.7-8.37-2.7-4.88,0-7.87,2.73-7.87,6.51v.06c0,4.18,3.34,5.41,7.67,6.39,2.76.64,3.43,1.11,3.43,1.95v.05c0,.88-.81,1.4-2.33,1.4-2.35,0-4.59-.84-6.59-2.45l-3.14,3.75c2.51,2.24,5.93,3.37,9.53,3.37m12.93-7h9.54v-4.86h-9.54v4.86Zm23.11,7c4.94,0,8.23-2.47,8.23-6.62v-.06c0-3.81-2.91-5.32-7.59-6.34-2.82-.64-3.51-1.04-3.51-1.97v-.06c0-.73.66-1.31,2.03-1.31,1.8,0,3.84.7,5.69,2.01l2.83-3.98c-2.21-1.77-4.91-2.7-8.37-2.7-4.88,0-7.88,2.73-7.88,6.51v.06c0,4.18,3.35,5.41,7.67,6.39,2.77.64,3.43,1.11,3.43,1.95v.05c0,.88-.81,1.4-2.32,1.4-2.36,0-4.6-.84-6.61-2.45l-3.14,3.75c2.51,2.24,5.93,3.37,9.54,3.37m9.48-.35h5.92l1.45-3.64h7.85l1.49,3.64h6.05l-8.66-20.48h-5.44l-8.66,20.48Zm9.04-8.02l2.29-5.78,2.28,5.78h-4.57Zm25.05,8.43c4.51,0,7.1-1.95,9.02-4.59l-4.24-3.02c-1.22,1.48-2.48,2.47-4.6,2.47-2.85,0-4.85-2.38-4.85-5.44v-.06c0-2.96,2-5.38,4.85-5.38,1.95,0,3.29.93,4.45,2.39l4.24-3.28c-1.8-2.5-4.47-4.24-8.63-4.24-6.16,0-10.72,4.65-10.72,10.58v.06c0,6.08,4.67,10.52,10.48,10.52m12.29-.41h15.49v-4.94h-9.85v-15.4h-5.65v20.34Zm17.16,0h5.92l1.46-3.64h7.85l1.48,3.64h6.05l-8.67-20.48h-5.44l-8.65,20.48Zm9.03-8.02l2.3-5.78,2.26,5.78h-4.57Zm19.24,8.02h5.67v-7.7l7.74-12.64h-6.31l-4.21,7.41-4.19-7.41h-6.42l7.73,12.72v7.62Z" />
      </g>
    </svg>
  )
}

const LogoSub = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 318.06 109.72" id="Calque_1_sub">
      <g className="cls_sub-9">
        <g data-name="Calque 1">
          <g>
            <path className="cls_sub-6" d="m175.8,41.2h4.39v-5.97l5.99-9.79h-4.88l-3.27,5.74-3.24-5.74h-4.97l5.99,9.85v5.9Zm-14.89-6.21l1.78-4.48,1.76,4.48h-3.53Zm-7,6.21h4.59l1.12-2.81h6.08l1.15,2.81h4.67l-6.7-15.86h-4.21l-6.71,15.86Zm-13.28,0h12v-3.83h-7.62v-11.93h-4.37v15.75Zm-9.51.31c3.49,0,5.49-1.51,6.98-3.55l-3.28-2.34c-.95,1.15-1.92,1.92-3.56,1.92-2.21,0-3.76-1.85-3.76-4.21v-.05c0-2.29,1.55-4.16,3.76-4.16,1.5,0,2.55.72,3.45,1.84l3.29-2.55c-1.39-1.92-3.46-3.28-6.68-3.28-4.77,0-8.3,3.6-8.3,8.19v.04c0,4.71,3.62,8.15,8.12,8.15m-19.41-6.53l1.79-4.48,1.75,4.48h-3.54Zm-6.99,6.21h4.58l1.13-2.81h6.07l1.15,2.81h4.68l-6.71-15.86h-4.21l-6.71,15.86Zm-7.35.27c3.83,0,6.38-1.91,6.38-5.13v-.04c0-2.95-2.25-4.12-5.88-4.91-2.18-.49-2.72-.8-2.72-1.52v-.04c0-.57.51-1.01,1.57-1.01,1.39,0,2.97.54,4.41,1.55l2.18-3.09c-1.7-1.37-3.8-2.08-6.48-2.08-3.78,0-6.11,2.11-6.11,5.03v.04c0,3.24,2.59,4.19,5.95,4.95,2.14.5,2.65.85,2.65,1.51v.04c0,.67-.63,1.08-1.8,1.08-1.83,0-3.55-.65-5.11-1.89l-2.43,2.91c1.94,1.73,4.58,2.61,7.37,2.61m-17.88-5.42h7.38v-3.76h-7.38v3.76Zm-10.01,5.42c3.82,0,6.36-1.91,6.36-5.13v-.04c0-2.95-2.25-4.12-5.87-4.91-2.19-.49-2.72-.8-2.72-1.52v-.04c0-.57.51-1.01,1.57-1.01,1.39,0,2.97.54,4.41,1.55l2.18-3.09c-1.71-1.37-3.8-2.08-6.48-2.08-3.78,0-6.1,2.11-6.1,5.03v.04c0,3.24,2.59,4.19,5.94,4.95,2.14.5,2.66.85,2.66,1.51v.04c0,.67-.63,1.08-1.8,1.08-1.83,0-3.56-.65-5.1-1.89l-2.44,2.91c1.93,1.73,4.59,2.61,7.39,2.61m-14.14-.27h4.39v-15.75h-4.39v15.75Zm-12.58-8.19v-3.78h2.86c1.47,0,2.37.63,2.37,1.87v.04c0,1.15-.86,1.87-2.34,1.87h-2.88Zm-4.36,8.19h4.36v-4.77h1.91l3.18,4.77h5.04l-3.77-5.49c1.97-.84,3.25-2.44,3.25-4.84v-.05c0-1.55-.47-2.77-1.4-3.67-1.06-1.07-2.72-1.71-5.13-1.71h-7.45v15.75Zm-12.83-6.21l1.79-4.48,1.75,4.48h-3.54Zm-7,6.21h4.59l1.12-2.81h6.08l1.15,2.81h4.68l-6.71-15.86h-4.2l-6.71,15.86Zm-8.28-7.92v-4.16h2.05c1.57,0,2.52.72,2.52,2.07v.04c0,1.24-.95,2.05-2.5,2.05h-2.07Zm-4.36,7.92h4.36v-4.5h2.16c3.91,0,6.77-1.95,6.77-5.67v-.04c0-3.49-2.56-5.54-6.55-5.54h-6.75v15.75Z" />
            <path className="cls_sub-3" d="m96.04,80.48c7.99,0,14.46-6.47,14.46-14.46s-6.47-14.46-14.46-14.46-14.45,6.47-14.45,14.46,6.47,14.46,14.45,14.46" />
            <path className="cls_sub-3" d="m152.62,17.75c3.05,0,5.53-2.47,5.53-5.53s-2.47-5.52-5.53-5.52-5.53,2.47-5.53,5.52,2.47,5.53,5.53,5.53" />
            <g>
              <path className="cls_sub-5" d="m160.59,95.53c1.73,0,2.75-1.04,2.75-2.39v-.05c0-1.55-1.08-2.39-2.82-2.39h-2.68v4.83h2.75Z" />
              <path className="cls_sub-2" d="m160.82,87.57h-2.98v3.13h2.68c1.74,0,2.82.84,2.82,2.39v.05c0,1.36-1.02,2.39-2.75,2.39h-2.75v3.09h2.64c3.54,0,6.38-1.89,6.38-5.55v-.04c0-3.23-2.28-5.46-6.04-5.46Z" />
              <polygon className="cls_sub-3" points="157.84 90.7 157.84 90.7 157.84 87.57 154.36 87.57 154.36 95.53 157.84 95.53 157.84 90.7" />
              <rect className="cls_sub-8" x="154.36" y="95.53" width="3.48" height="3.09" />
              <path className="cls_sub-5" d="m160.59,95.53c1.73,0,2.75-1.04,2.75-2.39v-.05c0-1.55-1.08-2.39-2.82-2.39h-2.68v4.83h2.75Z" />
              <path className="cls_sub-2" d="m160.82,87.57h-2.98v3.13h2.68c1.74,0,2.82.84,2.82,2.39v.05c0,1.36-1.02,2.39-2.75,2.39h-2.75v3.09h2.64c3.54,0,6.38-1.89,6.38-5.55v-.04c0-3.23-2.28-5.46-6.04-5.46Z" />
              <polygon className="cls_sub-3" points="157.84 90.7 157.84 90.7 157.84 87.57 154.36 87.57 154.36 95.53 157.84 95.53 157.84 90.7" />
              <rect className="cls_sub-8" x="154.36" y="95.53" width="3.48" height="3.09" />
              <g>
                <path className="cls_sub-5" d="m240.5,93.01v-.05c0-1.49-1.04-2.26-2.73-2.26h-3.45v4.54h3.52c1.69,0,2.66-.9,2.66-2.23Z" />
                <rect className="cls_sub-3" x="230.84" y="98.31" width="3.48" height="5.05" />
                <polygon className="cls_sub-3" points="234.32 90.7 234.32 87.57 230.84 87.57 230.84 95.24 234.32 95.24 234.32 90.7" />
                <rect className="cls_sub-8" x="230.84" y="95.24" width="3.48" height="3.07" />
                <path className="cls_sub-2" d="m240.63,97.73h.01c2-.75,3.38-2.35,3.38-4.9v-.04c0-1.49-.48-2.73-1.35-3.61-1.04-1.04-2.6-1.6-4.6-1.6h-3.75v3.13h3.45c1.69,0,2.73.77,2.73,2.26v.05c0,1.33-.97,2.23-2.66,2.23h-3.52v3.07h2.72l3.38,5.05h4.07l-3.87-5.64Z" />
              </g>
              <g>
                <polygon className="cls_sub-3" points="286.02 93.29 282.59 87.55 282.59 103.36 286.02 103.36 286.02 93.31 286.02 93.3 286.02 93.29" />
                <polygon className="cls_sub-3" points="293.19 87.55 293.15 87.55 293.15 97.22 293.19 97.28 296.62 103.36 296.62 87.55 293.19 87.55" />
                <polygon className="cls_sub-2" points="293.15 97.54 293.15 97.22 285.79 87.55 286.02 93.29 286.06 93.37 293.4 103.01 293.15 97.54" />
                <polygon className="cls_sub-8" points="285.79 87.55 282.59 87.55 286.02 93.29 285.79 87.55" />
                <polygon className="cls_sub-8" points="293.15 97.22 293.15 97.54 293.4 103.01 293.67 103.36 296.62 103.36 293.19 97.28 293.15 97.22" />
              </g>
              <g>
                <polygon className="cls_sub-5" points="190.41 91.61 188.34 96.75 192.51 96.75 190.43 91.66 190.41 91.61" />
                <polygon className="cls_sub-3" points="192.51 96.75 188.34 96.75 187.08 99.85 193.77 99.85 192.51 96.75" />
                <polygon className="cls_sub-2" points="195.22 103.4 198.86 103.4 197.34 99.85 193.77 99.85 195.22 103.4" />
                <polygon className="cls_sub-2" points="190.43 91.66 192.51 96.75 196.02 96.75 192.09 87.48 190.41 91.61 190.43 91.66" />
                <polygon className="cls_sub-8" points="193.77 99.85 197.34 99.85 196.02 96.75 192.51 96.75 193.77 99.85" />
                <polygon className="cls_sub-2" points="182.1 103.4 185.65 103.4 187.08 99.85 183.61 99.85 182.1 103.4" />
                <polygon className="cls_sub-2" points="190.41 91.61 188.88 87.48 184.94 96.75 188.34 96.75 190.41 91.61" />
                <polygon className="cls_sub-8" points="184.94 96.75 183.61 99.85 187.08 99.85 188.34 96.75 184.94 96.75" />
                <polygon className="cls_sub-4" points="192.09 87.48 188.88 87.48 190.41 91.61 192.09 87.48" />
              </g>
              <rect className="cls_sub-3" x="154.36" y="98.63" width="3.48" height="4.74" />
              <g>
                <rect className="cls_sub-3" x="169.35" y="87.57" width="3.48" height="12.65" />
                <rect className="cls_sub-2" x="172.83" y="100.21" width="7.88" height="3.15" />
                <rect className="cls_sub-8" x="169.35" y="100.21" width="3.48" height="3.15" />
              </g>
              <g>
                <polygon className="cls_sub-3" points="204.87 93.9 201.1 87.57 197.04 87.57 203.11 97.13 203.11 97.13 203.11 97.14 204.87 93.9" />
                <polygon className="cls_sub-2" points="206.6 97.08 213.14 87.75 208.7 87.57 204.86 93.9 203.11 97.14 203.11 97.14 203.11 97.15 206.6 97.07 206.6 97.08" />
                <polygon className="cls_sub-8" points="203.11 97.15 203.12 97.14 203.11 97.13 203.12 103.37 206.59 103.37 206.59 99.92 206.59 97.07 203.11 97.15" />
              </g>
              <g>
                <path className="cls_sub-3" d="m224.29,99.84c-.88.63-2,.94-3.27.94-2.8,0-4.79-2.12-4.79-5.01v-.05c0-2.68,2.01-4.92,4.54-4.92,1.83,0,2.92.58,4.14,1.6l2.19-2.64c-1.65-1.4-3.37-2.17-6.21-2.17-4.78,0-8.29,3.68-8.29,8.17v.04c0,4.68,3.39,8.13,8.31,8.13,2.89,0,5.13-1.13,6.75-2.51v-3.86h-3.36v2.26Z" />
                <rect className="cls_sub-2" x="220.79" y="94.57" width="3.5" height="3" />
                <polygon className="cls_sub-8" points="224.29 94.57 224.29 97.57 224.29 97.57 227.65 97.57 227.65 94.57 224.29 94.57" />
              </g>
              <g>
                <path className="cls_sub-2" d="m275.22,103.16s-.02,0-.03,0c0,0,.02,0,.03,0Z" />
                <path className="cls_sub-2" d="m278.96,98.9c.19-.74.28-1.5.28-2.42v-8.91h-3.47v9.05c0,.39-.03.73-.09,1.06l3.29,1.23h0Z" />
                <path className="cls_sub-2" d="m274.96,99.33h0s0,0,0,0Z" />
                <path className="cls_sub-3" d="m272.36,100.41s.02,0,0,0c-2.13,0-3.42-1.33-3.42-3.91v-8.94h-3.47v9.03c0,4.65,2.65,7.03,6.89,7.03.02,0-.02,0-.02,0l.02-3.2h0Z" />
                <path className="cls_sub-8" d="m278.12,100.89c.04-.07.08-.13.12-.2.06-.11.12-.22.18-.34.04-.07.07-.14.11-.22.06-.12.11-.24.15-.37.04-.08.06-.15.09-.23.06-.17.11-.34.15-.52.02-.04.02-.08.04-.12l-3.3-1.23c-.12.67-.36,1.23-.71,1.65,0,0,0,0,0,0-.35.42-.79.72-1.33.89-.02,0-.03.01-.06.02-.17.05-.34.09-.53.11-.02,0-.05.01-.06.01-.2.03-.4.04-.61.04v3.2h.05c.22,0,.44-.01.65-.03.06,0,.13-.01.2-.02.16-.01.34-.03.5-.06.07,0,.14-.02.2-.03.16-.03.32-.06.47-.1.06-.01.12-.02.18-.04.2-.05.4-.11.58-.17,0,0,.01,0,.01,0,.21-.07.4-.16.57-.23.06-.03.11-.05.16-.08.13-.07.26-.13.38-.2.06-.04.12-.07.17-.11.12-.07.21-.14.32-.22.06-.04.12-.08.17-.13.1-.09.21-.18.31-.28.04-.03.09-.07.13-.11.13-.14.26-.28.37-.43.04-.04.07-.09.1-.12.08-.12.17-.23.25-.36Z" />
              </g>
              <g>
                <rect className="cls_sub-3" x="300.33" y="90.7" width="3.47" height="12.67" />
                <path className="cls_sub-2" d="m303.8,87.57v3.13h2.69c2.84,0,4.76,1.96,4.76,4.76v.04c0,2.8-1.92,4.72-4.76,4.72h-2.69v3.14h2.69c4.96,0,8.39-3.46,8.39-7.9v-.05c0-4.45-3.43-7.85-8.39-7.85h-2.69Z" />
                <rect className="cls_sub-8" x="300.33" y="87.57" width="3.47" height="3.13" />
              </g>
              <g>
                <path className="cls_sub-5" d="m254.59,90.8c-2.8,0-4.74,2.22-4.74,4.92v.05c0,2.71,1.98,4.96,4.78,4.96s4.74-2.21,4.74-4.92v-.04c0-2.71-1.98-4.97-4.78-4.97Z" />
                <path className="cls_sub-2" d="m254.63,87.6c-4.87,0-8.42,3.68-8.42,8.17v.02h3.64c0,2.7,1.97,4.95,4.76,4.97v3.18c4.87,0,8.4-3.68,8.4-8.17v-.05c0-4.49-3.5-8.12-8.38-8.12Zm4.74,8.22c0,2.71-1.94,4.92-4.74,4.92s-4.78-2.25-4.78-4.96v-.05c0-2.7,1.94-4.92,4.74-4.92s4.78,2.26,4.78,4.97v.04Z" />
                <path className="cls_sub-1" d="m246.22,95.81v.02c0,4.49,3.5,8.13,8.37,8.13h0v-.03s0,0,0,0c-4.88,0-8.37-3.63-8.37-8.12Z" />
                <path className="cls_sub-8" d="m249.85,95.79h-3.64v.02c0,4.5,3.5,8.12,8.37,8.12h0v-3.18c-2.78-.01-4.75-2.27-4.75-4.97Z" />
              </g>
              <g>
                <path className="cls_sub-5" d="m240.5,93.01v-.05c0-1.49-1.04-2.26-2.73-2.26h-3.45v4.54h3.52c1.69,0,2.66-.9,2.66-2.23Z" />
                <rect className="cls_sub-3" x="230.84" y="98.31" width="3.48" height="5.05" />
                <polygon className="cls_sub-3" points="234.32 90.7 234.32 87.57 230.84 87.57 230.84 95.24 234.32 95.24 234.32 90.7" />
                <rect className="cls_sub-8" x="230.84" y="95.24" width="3.48" height="3.07" />
                <path className="cls_sub-2" d="m240.63,97.73h.01c2-.75,3.38-2.35,3.38-4.9v-.04c0-1.49-.48-2.73-1.35-3.61-1.04-1.04-2.6-1.6-4.6-1.6h-3.75v3.13h3.45c1.69,0,2.73.77,2.73,2.26v.05c0,1.33-.97,2.23-2.66,2.23h-3.52v3.07h2.72l3.38,5.05h4.07l-3.87-5.64Z" />
              </g>
              <g>
                <polygon className="cls_sub-3" points="286.02 93.29 282.59 87.55 282.59 103.36 286.02 103.36 286.02 93.31 286.02 93.3 286.02 93.29" />
                <polygon className="cls_sub-3" points="293.19 87.55 293.15 87.55 293.15 97.22 293.19 97.28 296.62 103.36 296.62 87.55 293.19 87.55" />
                <polygon className="cls_sub-2" points="293.15 97.54 293.15 97.22 285.79 87.55 286.02 93.29 286.06 93.37 293.4 103.01 293.15 97.54" />
                <polygon className="cls_sub-8" points="285.79 87.55 282.59 87.55 286.02 93.29 285.79 87.55" />
                <polygon className="cls_sub-8" points="293.15 97.22 293.15 97.54 293.4 103.01 293.67 103.36 296.62 103.36 293.19 97.28 293.15 97.22" />
              </g>
              <g>
                <polygon className="cls_sub-5" points="190.41 91.61 188.34 96.75 192.51 96.75 190.43 91.66 190.41 91.61" />
                <polygon className="cls_sub-3" points="192.51 96.75 188.34 96.75 187.08 99.85 193.77 99.85 192.51 96.75" />
                <polygon className="cls_sub-2" points="195.22 103.4 198.86 103.4 197.34 99.85 193.77 99.85 195.22 103.4" />
                <polygon className="cls_sub-2" points="190.43 91.66 192.51 96.75 196.02 96.75 192.09 87.48 190.41 91.61 190.43 91.66" />
                <polygon className="cls_sub-8" points="193.77 99.85 197.34 99.85 196.02 96.75 192.51 96.75 193.77 99.85" />
                <polygon className="cls_sub-2" points="182.1 103.4 185.65 103.4 187.08 99.85 183.61 99.85 182.1 103.4" />
                <polygon className="cls_sub-2" points="190.41 91.61 188.88 87.48 184.94 96.75 188.34 96.75 190.41 91.61" />
                <polygon className="cls_sub-8" points="184.94 96.75 183.61 99.85 187.08 99.85 188.34 96.75 184.94 96.75" />
                <polygon className="cls_sub-4" points="192.09 87.48 188.88 87.48 190.41 91.61 192.09 87.48" />
              </g>
              <rect className="cls_sub-3" x="154.36" y="98.63" width="3.48" height="4.74" />
              <g>
                <rect className="cls_sub-3" x="169.35" y="87.57" width="3.48" height="12.65" />
                <rect className="cls_sub-2" x="172.83" y="100.21" width="7.88" height="3.15" />
                <rect className="cls_sub-8" x="169.35" y="100.21" width="3.48" height="3.15" />
              </g>
              <g>
                <polygon className="cls_sub-3" points="204.87 93.9 201.1 87.57 197.04 87.57 203.11 97.13 203.11 97.13 203.11 97.14 204.87 93.9" />
                <polygon className="cls_sub-2" points="206.6 97.08 213.14 87.75 208.7 87.57 204.86 93.9 203.11 97.14 203.11 97.14 203.11 97.15 206.6 97.07 206.6 97.08" />
                <polygon className="cls_sub-8" points="203.11 97.15 203.12 97.14 203.11 97.13 203.12 103.37 206.59 103.37 206.59 99.92 206.59 97.07 203.11 97.15" />
              </g>
              <g>
                <path className="cls_sub-3" d="m224.29,99.84c-.88.63-2,.94-3.27.94-2.8,0-4.79-2.12-4.79-5.01v-.05c0-2.68,2.01-4.92,4.54-4.92,1.83,0,2.92.58,4.14,1.6l2.19-2.64c-1.65-1.4-3.37-2.17-6.21-2.17-4.78,0-8.29,3.68-8.29,8.17v.04c0,4.68,3.39,8.13,8.31,8.13,2.89,0,5.13-1.13,6.75-2.51v-3.86h-3.36v2.26Z" />
                <rect className="cls_sub-2" x="220.79" y="94.57" width="3.5" height="3" />
                <polygon className="cls_sub-8" points="224.29 94.57 224.29 97.57 224.29 97.57 227.65 97.57 227.65 94.57 224.29 94.57" />
              </g>
              <g>
                <path className="cls_sub-2" d="m275.22,103.16s-.02,0-.03,0c0,0,.02,0,.03,0Z" />
                <path className="cls_sub-2" d="m278.96,98.9c.19-.74.28-1.5.28-2.42v-8.91h-3.47v9.05c0,.39-.03.73-.09,1.06l3.29,1.23h0Z" />
                <path className="cls_sub-2" d="m274.96,99.33h0s0,0,0,0Z" />
                <path className="cls_sub-3" d="m272.36,100.41s.02,0,0,0c-2.13,0-3.42-1.33-3.42-3.91v-8.94h-3.47v9.03c0,4.65,2.65,7.03,6.89,7.03.02,0-.02,0-.02,0l.02-3.2h0Z" />
                <path className="cls_sub-8" d="m278.12,100.89c.04-.07.08-.13.12-.2.06-.11.12-.22.18-.34.04-.07.07-.14.11-.22.06-.12.11-.24.15-.37.04-.08.06-.15.09-.23.06-.17.11-.34.15-.52.02-.04.02-.08.04-.12l-3.3-1.23c-.12.67-.36,1.23-.71,1.65,0,0,0,0,0,0-.35.42-.79.72-1.33.89-.02,0-.03.01-.06.02-.17.05-.34.09-.53.11-.02,0-.05.01-.06.01-.2.03-.4.04-.61.04v3.2h.05c.22,0,.44-.01.65-.03.06,0,.13-.01.2-.02.16-.01.34-.03.5-.06.07,0,.14-.02.2-.03.16-.03.32-.06.47-.1.06-.01.12-.02.18-.04.2-.05.4-.11.58-.17,0,0,.01,0,.01,0,.21-.07.4-.16.57-.23.06-.03.11-.05.16-.08.13-.07.26-.13.38-.2.06-.04.12-.07.17-.11.12-.07.21-.14.32-.22.06-.04.12-.08.17-.13.1-.09.21-.18.31-.28.04-.03.09-.07.13-.11.13-.14.26-.28.37-.43.04-.04.07-.09.1-.12.08-.12.17-.23.25-.36Z" />
              </g>
              <g>
                <rect className="cls_sub-3" x="300.33" y="90.7" width="3.47" height="12.67" />
                <path className="cls_sub-2" d="m303.8,87.57v3.13h2.69c2.84,0,4.76,1.96,4.76,4.76v.04c0,2.8-1.92,4.72-4.76,4.72h-2.69v3.14h2.69c4.96,0,8.39-3.46,8.39-7.9v-.05c0-4.45-3.43-7.85-8.39-7.85h-2.69Z" />
                <rect className="cls_sub-8" x="300.33" y="87.57" width="3.47" height="3.13" />
              </g>
              <g>
                <path className="cls_sub-5" d="m254.59,90.8c-2.8,0-4.74,2.22-4.74,4.92v.05c0,2.71,1.98,4.96,4.78,4.96s4.74-2.21,4.74-4.92v-.04c0-2.71-1.98-4.97-4.78-4.97Z" />
                <path className="cls_sub-2" d="m254.63,87.6c-4.87,0-8.42,3.68-8.42,8.17v.02h3.64c0,2.7,1.97,4.95,4.76,4.97v3.18c4.87,0,8.4-3.68,8.4-8.17v-.05c0-4.49-3.5-8.12-8.38-8.12Zm4.74,8.22c0,2.71-1.94,4.92-4.74,4.92s-4.78-2.25-4.78-4.96v-.05c0-2.7,1.94-4.92,4.74-4.92s4.78,2.26,4.78,4.97v.04Z" />
                <path className="cls_sub-1" d="m246.22,95.81v.02c0,4.49,3.5,8.13,8.37,8.13h0v-.03s0,0,0,0c-4.88,0-8.37-3.63-8.37-8.12Z" />
                <path className="cls_sub-8" d="m249.85,95.79h-3.64v.02c0,4.5,3.5,8.12,8.37,8.12h0v-3.18c-2.78-.01-4.75-2.27-4.75-4.97Z" />
              </g>
            </g>
            <path className="cls_sub-1" d="m129.84,58.5h3.48v15.8h-3.48v-15.8Z" />
            <rect className="cls_sub-3" x="234.42" y="58.5" width="3.48" height="15.8" />
            <g>
              <rect className="cls_sub-3" x="223.59" y="61.7" width="3.47" height="12.59" />
              <polygon className="cls_sub-2" points="231.64 58.5 218.55 58.5 218.55 61.7 223.59 61.7 223.59 58.5 227.06 58.5 227.06 61.7 231.64 61.7 231.64 58.5" />
              <rect className="cls_sub-8" x="223.59" y="58.5" width="3.47" height="3.2" />
            </g>
            <g>
              <polygon className="cls_sub-3" points="263.91 64.3 260.48 58.56 260.48 74.36 263.91 74.36 263.91 64.31 263.91 64.3 263.91 64.3" />
              <polygon className="cls_sub-3" points="271.09 58.56 271.04 58.56 271.04 68.23 271.09 68.28 274.52 74.36 274.52 58.56 271.09 58.56" />
              <polygon className="cls_sub-2" points="271.04 68.54 271.04 68.23 263.68 58.56 263.91 64.3 263.95 64.37 271.3 74.01 271.04 68.54" />
              <polygon className="cls_sub-8" points="263.68 58.56 260.48 58.56 263.91 64.3 263.68 58.56" />
              <polygon className="cls_sub-8" points="271.04 68.23 271.04 68.54 271.3 74.01 271.57 74.36 274.52 74.36 271.09 68.28 271.04 68.23" />
            </g>
            <g>
              <polygon className="cls_sub-2" points="204.53 58.5 200.78 58.5 196.65 69.63 196.65 69.63 196.65 69.63 198.13 74.41 198.13 74.41 204.53 58.5" />
              <polygon className="cls_sub-3" points="196.65 69.63 192.52 58.5 188.68 58.5 195.07 74.41 198.13 74.41 198.13 74.41 195.07 74.41 196.65 69.63" />
              <polygon className="cls_sub-8" points="196.65 69.63 195.07 74.41 198.13 74.41 196.65 69.63 196.65 69.63" />
            </g>
            <rect className="cls_sub-2" x="217.66" y="67.52" width="0" height="3.37" transform="translate(-9.77 90.19) rotate(-22.91)" />
            <rect className="cls_sub-2" x="203.56" y="69.21" width="3.37" transform="translate(61.25 231.02) rotate(-66.97)" />
            <g>
              <polygon className="cls_sub-5" points="211.39 62.51 209.31 67.66 213.49 67.66 211.41 62.56 211.39 62.51" />
              <polygon className="cls_sub-2" points="213.49 67.66 209.31 67.66 208.06 70.76 214.75 70.76 213.49 67.66" />
              <polygon className="cls_sub-2" points="216.2 74.3 219.83 74.3 218.32 70.76 214.75 70.76 216.2 74.3" />
              <polygon className="cls_sub-2" points="211.41 62.56 213.49 67.66 217 67.66 213.05 58.38 211.39 62.51 211.41 62.56" />
              <polygon className="cls_sub-4" points="214.75 70.76 218.32 70.76 217 67.66 213.49 67.66 214.75 70.76" />
              <polygon className="cls_sub-3" points="203.08 74.3 206.63 74.3 208.06 70.76 204.59 70.76 203.08 74.3" />
              <polygon className="cls_sub-3" points="211.39 62.51 209.85 58.38 205.91 67.66 209.31 67.66 211.39 62.51" />
              <polygon className="cls_sub-8" points="205.91 67.66 204.59 70.76 208.06 70.76 209.31 67.66 205.91 67.66" />
              <polygon className="cls_sub-8" points="213.05 58.38 209.85 58.38 211.39 62.51 213.05 58.38" />
            </g>
            <g>
              <path className="cls_sub-3" d="m253.69,68.28s0,0,0,0c0,0,0,0,0,0Z" />
              <path className="cls_sub-3" d="m256.49,70.58l-3.04-1.76c-.77,1.51-2.28,2.54-4.19,2.54-2.8,0-4.79-2.26-4.79-4.96h-3.63v.04c0,4.49,3.51,8.13,8.38,8.13,3.26,0,5.85-1.64,7.27-4h0Z" />
              <path className="cls_sub-2" d="m257.64,66.35c0-4.49-3.5-8.13-8.37-8.13s-8.42,3.68-8.42,8.17h3.63v-.04c0-2.71,1.94-4.92,4.74-4.92s4.79,2.25,4.79,4.96h0s3.62,0,3.62,0h0v-.05Z" />
              <path className="cls_sub-8" d="m254.01,66.44h0c0,.2-.02.4-.04.59,0,.05-.01.1-.02.14-.02.16-.05.3-.08.45,0,.04-.01.08-.03.12-.04.18-.09.35-.15.53,0,0,0,0,0,0-.07.19-.15.37-.23.55l3.04,1.76c.26-.44.48-.91.65-1.39.02-.04.04-.08.04-.12.05-.14.1-.28.14-.43.03-.11.06-.23.09-.35.03-.14.06-.29.09-.45.03-.16.06-.33.07-.49,0-.08.02-.16.03-.24.02-.23.03-.47.03-.71h0s-3.62,0-3.62,0v.04Z" />
            </g>
            <g>
              <path className="cls_sub-2" d="m179.83,58.29c-1.04,0-2.02.18-2.91.48l1.1,3.06c.54-.22,1.13-.33,1.77-.33,2.8,0,4.79,2.25,4.79,4.96v.05c0,2.71-1.94,4.92-4.74,4.92s-4.79-2.26-4.79-4.97v-.05c0-.28.02-.55.07-.82l-3.58-.47c-.07.44-.12.88-.12,1.34v.05c0,4.49,3.5,8.12,8.38,8.12s8.42-3.68,8.42-8.17v-.05c0-4.49-3.5-8.13-8.38-8.13Z" />
              <path className="cls_sub-4" d="m178.02,61.83l-1.1-3.06c-2.88.99-4.91,3.4-5.39,6.35l3.58.47c.26-1.73,1.33-3.15,2.91-3.76Z" />
            </g>
            <rect className="cls_sub-2" x="217.66" y="67.52" width="0" height="3.37" transform="translate(-9.77 90.19) rotate(-22.91)" />
            <rect className="cls_sub-2" x="203.56" y="69.21" width="3.37" transform="translate(61.25 231.02) rotate(-66.97)" />
            <path className="cls_sub-3" d="m129.84,58.5h3.48v15.8h-3.48v-15.8Z" />
            <rect className="cls_sub-3" x="234.42" y="58.5" width="3.48" height="15.8" />
            <g>
              <rect className="cls_sub-3" x="223.59" y="61.7" width="3.47" height="12.59" />
              <polygon className="cls_sub-2" points="231.64 58.5 218.55 58.5 218.55 61.7 223.59 61.7 223.59 58.5 227.06 58.5 227.06 61.7 231.64 61.7 231.64 58.5" />
              <rect className="cls_sub-8" x="223.59" y="58.5" width="3.47" height="3.2" />
            </g>
            <g>
              <polygon className="cls_sub-2" points="140.43 64.3 137 58.56 137 74.36 140.43 74.36 140.43 64.31 140.43 64.3 140.43 64.3" />
              <polygon className="cls_sub-3" points="147.61 58.56 147.57 58.56 147.57 68.23 147.61 68.28 151.04 74.36 151.04 58.56 147.61 58.56" />
              <polygon className="cls_sub-2" points="147.57 68.54 147.57 68.23 140.2 58.56 140.43 64.3 140.48 64.37 147.82 74.01 147.57 68.54" />
              <polygon className="cls_sub-4" points="140.2 58.56 137 58.56 140.43 64.3 140.2 58.56" />
              <polygon className="cls_sub-8" points="147.57 68.23 147.57 68.54 147.82 74.01 148.08 74.36 151.04 74.36 147.61 68.28 147.57 68.23" />
            </g>
            <g>
              <polygon className="cls_sub-2" points="158.01 64.3 154.58 58.56 154.58 74.36 158.01 74.36 158.01 64.31 158.01 64.3 158.01 64.3" />
              <polygon className="cls_sub-3" points="165.18 58.56 165.14 58.56 165.14 68.23 165.18 68.28 168.62 74.36 168.62 58.56 165.18 58.56" />
              <polygon className="cls_sub-3" points="165.14 68.54 165.14 68.23 157.79 58.56 158.01 64.3 158.06 64.37 165.4 74.01 165.14 68.54" />
              <polygon className="cls_sub-8" points="157.79 58.56 154.58 58.56 158.01 64.3 157.79 58.56" />
              <polygon className="cls_sub-7" points="165.14 68.23 165.14 68.54 165.4 74.01 165.66 74.36 168.62 74.36 165.18 68.28 165.14 68.23" />
            </g>
            <g>
              <polygon className="cls_sub-3" points="263.91 64.3 260.48 58.56 260.48 74.36 263.91 74.36 263.91 64.31 263.91 64.3 263.91 64.3" />
              <polygon className="cls_sub-3" points="271.09 58.56 271.04 58.56 271.04 68.23 271.09 68.28 274.52 74.36 274.52 58.56 271.09 58.56" />
              <polygon className="cls_sub-2" points="271.04 68.54 271.04 68.23 263.68 58.56 263.91 64.3 263.95 64.37 271.3 74.01 271.04 68.54" />
              <polygon className="cls_sub-8" points="263.68 58.56 260.48 58.56 263.91 64.3 263.68 58.56" />
              <polygon className="cls_sub-8" points="271.04 68.23 271.04 68.54 271.3 74.01 271.57 74.36 274.52 74.36 271.09 68.28 271.04 68.23" />
            </g>
            <g>
              <polygon className="cls_sub-2" points="204.53 58.5 200.78 58.5 196.65 69.63 196.65 69.63 196.65 69.63 198.13 74.41 198.13 74.41 204.53 58.5" />
              <polygon className="cls_sub-3" points="196.65 69.63 192.52 58.5 188.68 58.5 195.07 74.41 198.13 74.41 198.13 74.41 195.07 74.41 196.65 69.63" />
              <polygon className="cls_sub-8" points="196.65 69.63 195.07 74.41 198.13 74.41 196.65 69.63 196.65 69.63" />
            </g>
            <g>
              <polygon className="cls_sub-5" points="211.39 62.51 209.31 67.66 213.49 67.66 211.41 62.56 211.39 62.51" />
              <polygon className="cls_sub-2" points="213.49 67.66 209.31 67.66 208.06 70.76 214.75 70.76 213.49 67.66" />
              <polygon className="cls_sub-2" points="216.2 74.3 219.83 74.3 218.32 70.76 214.75 70.76 216.2 74.3" />
              <polygon className="cls_sub-2" points="211.41 62.56 213.49 67.66 217 67.66 213.05 58.38 211.39 62.51 211.41 62.56" />
              <polygon className="cls_sub-4" points="214.75 70.76 218.32 70.76 217 67.66 213.49 67.66 214.75 70.76" />
              <polygon className="cls_sub-3" points="203.08 74.3 206.63 74.3 208.06 70.76 204.59 70.76 203.08 74.3" />
              <polygon className="cls_sub-3" points="211.39 62.51 209.85 58.38 205.91 67.66 209.31 67.66 211.39 62.51" />
              <polygon className="cls_sub-8" points="205.91 67.66 204.59 70.76 208.06 70.76 209.31 67.66 205.91 67.66" />
              <polygon className="cls_sub-8" points="213.05 58.38 209.85 58.38 211.39 62.51 213.05 58.38" />
            </g>
            <g>
              <path className="cls_sub-3" d="m253.69,68.28s0,0,0,0c0,0,0,0,0,0Z" />
              <path className="cls_sub-3" d="m256.49,70.58l-3.04-1.76c-.77,1.51-2.28,2.54-4.19,2.54-2.8,0-4.79-2.26-4.79-4.96h-3.63v.04c0,4.49,3.51,8.13,8.38,8.13,3.26,0,5.85-1.64,7.27-4h0Z" />
              <path className="cls_sub-2" d="m257.64,66.35c0-4.49-3.5-8.13-8.37-8.13s-8.42,3.68-8.42,8.17h3.63v-.04c0-2.71,1.94-4.92,4.74-4.92s4.79,2.25,4.79,4.96h0s3.62,0,3.62,0h0v-.05Z" />
              <path className="cls_sub-8" d="m254.01,66.44h0c0,.2-.02.4-.04.59,0,.05-.01.1-.02.14-.02.16-.05.3-.08.45,0,.04-.01.08-.03.12-.04.18-.09.35-.15.53,0,0,0,0,0,0-.07.19-.15.37-.23.55l3.04,1.76c.26-.44.48-.91.65-1.39.02-.04.04-.08.04-.12.05-.14.1-.28.14-.43.03-.11.06-.23.09-.35.03-.14.06-.29.09-.45.03-.16.06-.33.07-.49,0-.08.02-.16.03-.24.02-.23.03-.47.03-.71h0s-3.62,0-3.62,0v.04Z" />
            </g>
            <g>
              <path className="cls_sub-2" d="m179.83,58.29c-1.04,0-2.02.18-2.91.48l1.1,3.06c.54-.22,1.13-.33,1.77-.33,2.8,0,4.79,2.25,4.79,4.96v.05c0,2.71-1.94,4.92-4.74,4.92s-4.79-2.26-4.79-4.97v-.05c0-.28.02-.55.07-.82l-3.58-.47c-.07.44-.12.88-.12,1.34v.05c0,4.49,3.5,8.12,8.38,8.12s8.42-3.68,8.42-8.17v-.05c0-4.49-3.5-8.13-8.38-8.13Z" />
              <path className="cls_sub-4" d="m178.02,61.83l-1.1-3.06c-2.88.99-4.91,3.4-5.39,6.35l3.58.47c.26-1.73,1.33-3.15,2.91-3.76Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { Logo, LogoSub }