import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { partitionBy } from "../../utils/utils";
import { Arrow, Divider } from "../../assets";
import { ClassicButton } from "../../components/buttons/Buttons";
import { EditionContext, LangContext } from "../../App";
import "moment/locale/fr";
import "./EventPage.css";
import "./EventPage_Resp.css";
import { BackendClient } from "../../utils/api_request";
import { MarkdownText } from "../../components/markdown/MarkdownText";

const EventPage = () => {
  const { lang } = useContext(LangContext);
  const { edition } = useContext(EditionContext);
  moment.locale(lang);
  const [eventsPerDay, setEventsPerDay] = useState();
  const [villagesPerDay, setVillagesPerDay] = useState(new Map());
  const [daySummary, setDaySummary] = useState();
  useEffect(() => {
    BackendClient.post("/events", { edition: edition._id })
      .then((data) => data.data)
      .then((data) => {
        const sorted = data.sort((e1, e2) =>
          e1.date_begin.localeCompare(e2.date_begin)
        );
        setEventsPerDay(
          partitionBy(sorted, (event) => event.date_begin.slice(0, 10))
        );
      });
    setDaySummary(edition.program_summary);
    BackendClient.post("/village_stands", { edition: edition._id })
      .then((data) => data.data)
      .then((data) => {
        setVillagesPerDay(
          partitionBy(data.data, (stand) => stand.date.slice(0, 10))
        );
      });
  }, [edition._id, edition.program_summary]);
  window.scrollTo(0, 0);
  return (
    <div className="event-page">
      <p className="event-page-title">
        {edition.translations["eventpage/program/title"][lang]}
      </p>
      <div className="event-page-summary">
        {daySummary ? (
          daySummary.map((date, index, array) => (
            <DaySummary
              day={date}
              key={index}
              isFirst={index === 0}
              isLast={index === array.length - 1}
              lang={lang}
            />
          ))
        ) : (
          <p>Loading</p>
        )}
      </div>
      <ClassicButton
        text={edition.translations["eventpage/program/download"][lang]}
        onClick={() => {
          window.open(edition.program_link);
        }}
      />
      <img src={Divider} alt="big-divider" />
      {eventsPerDay ? (
        <div className="event-page-events">
          {[...new Set([...eventsPerDay.keys(), ...villagesPerDay.keys()])]
            .sort()
            .map((day, index) => (
              <div className="event-page-list" key={index}>
                <p>
                  <span>
                    {edition.translations["eventpage/day"][lang]} {index + 1}{" "}
                  </span>{" "}
                  <span>
                    {moment(day, "yyyy-MM-DD").format("Do MMMM").toUpperCase()}
                  </span>
                </p>
                <EventsOfDay
                  events={eventsPerDay.get(day)}
                  lang={lang}
                  edition={edition}
                />
                <VillagesOfDay
                  villages={villagesPerDay.get(day)}
                  lang={lang}
                  edition={edition}
                />
              </div>
            ))}
        </div>
      ) : (
        <p>Loading</p>
      )}
    </div>
  );
};

const VillagesOfDay = ({ villages, lang, edition }) => {
  if (!villages || villages.length === 0) return null;
  return (
    <div className="event-page-village-day">
      <div className="event-page-village-desc">
        <div className="markdown-text">
          <MarkdownText>
            {edition.translations["eventpage/vilages/desc"][lang]}
          </MarkdownText>
        </div>
      </div>
      <div className="event-page-villages">
        {[...partitionBy(villages, (stand) => stand.village).entries()].map(
          ([village, stands], index) => (
            <Village
              village={village}
              stands={stands}
              key={index}
              lang={lang}
              edition={edition}
            />
          )
        )}
      </div>
    </div>
  );
};

const Village = ({ village, lang, stands, edition }) => {
  return (
    <div className="event-page-village">
      <p style={{ color: village.color }}>
        {`Village ${village.title[lang]}`.toUpperCase()}
      </p>
      <div className="event-page-village-companies">
        {stands.map((company, index) => (
          <CompanyOfVillage company={company} key={index} lang={lang} />
        ))}
      </div>
    </div>
  );
};

const CompanyOfVillage = ({ company, lang }) => {
  return (
    <div className="event-page-village-company" id={"company@" + company.name}>
      <div
        className="company-flag unselectable"
        onClick={() => handleCompanyClick(company.name)}
      >
        <Arrow color="rgb(0, 0, 0)" size="1rem" />
        <p>{(company.name + " - " + company.place).toUpperCase()}</p>
      </div>
      <div className="village-company-data">
        {company.events.map((event, index) => (
          <div key={index}>
            <p>
              <span>{event.period}</span> : <span>{event.name[lang]}</span>
            </p>
            <p>{event.desc[lang]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const EventsOfDay = ({ events, lang, edition }) => {
  if (!events) return null;
  const places = partitionBy(events, (event) => event.place);
  if (places.size === 0) return null;
  return (
    <div className="event-page-day">
      <div className="event-page-day-desc">
        <div className="markdown-text">
          <MarkdownText>
            {edition.translations["eventpage/stands/desc"][lang]}
          </MarkdownText>
        </div>
      </div>
      {[...places.keys()]
        .sort((a, b) => b.localeCompare(a))
        .map((place, index) => (
          <EventsOfPlace
            place={place}
            events={places.get(place)}
            key={index}
            lang={lang}
          />
        ))}
    </div>
  );
};

const EventsOfPlace = ({ events, place, lang }) => {
  return (
    <div className="event-page-place">
      <p>{place.toUpperCase()}</p>
      <div className="event-page-day-place">
        {events.map((event, index) => (
          <Event event={event} key={index} lang={lang} />
        ))}
      </div>
    </div>
  );
};

const Event = ({ event, lang }) => {
  const date_begin = moment(event.date_begin).subtract(1, "hour");
  const date_end = moment(event.date_end).subtract(1, "hour");
  return (
    <div className="event-item" id={"event@" + event._id}>
      <p className="event-info-date-float">{date_begin.format("HH[h]mm")}</p>
      <div
        className="event-flag unselectable"
        onClick={() => handleEventClick(event._id)}
      >
        <Arrow color="var(--blue)" size="1.5rem" />
        <p>{event.name[lang].toUpperCase()}</p>
      </div>
      <div className="event-item-infos">
        <div className="event-item-infos-solid">
          <div className="event-item-infos-inner">
            <div className="event-item-infos-text">
              <p className="event-info-date">
                {date_begin.format("HH[h]mm")} - {date_end.format("HH[h]mm")}
              </p>
              <p className="event-info-place">{event.place}</p>
              <p>{event.description[lang]}</p>
              <div className="event-item-companies">
                {event.entities.map((entity, index) => (
                  <img src={entity.iconURL} key={index} alt="empty" />
                ))}
              </div>
            </div>
            {event.speakers.length > 0 ? (
              <div className="event-card-speakers">
                {event.speakers.map((speaker, index) => (
                  <Speaker speaker={speaker} key={index} />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const Speaker = ({ speaker }) => {
  return (
    <div className="speaker-pin">
      <div className="speaker-pin-picture">
        <img src={speaker.pictureURL} alt="speaker" />
      </div>
      <div className="speaker-pin-infos">
        <p>{speaker.name}</p>
        <p>{speaker.status.fr}</p>
      </div>
    </div>
  );
};

const DaySummary = ({ day, isFirst = false, isLast = false, lang }) => {
  return (
    <div className="event-day-summary">
      <p style={{ color: day.color }}>
        {moment(day.date).format("DD MMMM YYYY").toUpperCase()}
      </p>
      <div
        className={
          "event-day-data" +
          (isFirst ? " event-day-first" : "") +
          (isLast ? " event-day-last" : "")
        }
      >
        {day.items.map((data, index) => (
          <div className="event-day-data-item" key={index}>
            <p>{data.title[lang].toUpperCase()}</p>
            <div className="event-day-data-subs">
              {data.subItems.map((sub, index2) => (
                <p key={index2}>{sub.title[lang]}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const handleEventClick = (_id) => {
  const eventDiv = document.getElementById("event@" + _id);
  const events = document.getElementsByClassName("event-item");
  for (let i = 0; i < events.length; i++) {
    if (events[i] !== eventDiv) events[i].classList.remove("event-selected");
  }
  if (eventDiv.classList.contains("event-selected")) {
    eventDiv.classList.remove("event-selected");
  } else {
    eventDiv.classList.add("event-selected");
  }
};

const handleCompanyClick = (_id) => {
  const companyDiv = document.getElementById("company@" + _id);
  if (companyDiv.classList.contains("company-selected")) {
    companyDiv.classList.remove("company-selected");
  } else {
    companyDiv.classList.add("company-selected");
  }
};

export { EventPage };
