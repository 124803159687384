import React, { useContext } from 'react'
import { EditionContext, LangContext } from '../../App';
import { Arrow } from '../../assets';
import { Group1 } from '../../components/props/Props';
import './PreviousPage.css'
import './PreviousPage_Resp.css'

var currentStartup = 0;

const PreviousPage = () => {

    const { edition } = useContext(EditionContext)

    const { lang } = useContext(LangContext)
    return (
        <div>
            <div className="spring-news">
                <Group1 />
                <p>{edition.translations["previous_editions/live_again"][lang].toUpperCase()}</p>
                <div className="spring-news-startup">
                    {edition.videos.map((data, index) =>
                        <div style={{ transform: `translate(${(index - currentStartup) * 100}%)` }} className={(index === currentStartup ? "spring-startup-selected" : "") + " spring-news-startup-div"}>
                            <p>{data.title[lang].toUpperCase()}</p>
                            <iframe
                                key={index}
                                src={`https://www.youtube.com/embed/${data.link}`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                            />
                            <p>{data.desc[lang]}</p>
                        </div>
                    )}
                </div>
                <div className='spring-news-startup-buttons'>
                    <button id="startup-previous" onClick={() => { createMovementForStartup(-1, edition.videos) }}><Arrow color="var(--blue)" /></button>
                    <button id="startup-next" onClick={() => { createMovementForStartup(+1, edition.videos) }}><Arrow color="var(--blue)" /></button>
                </div>
                <p>{edition.translations["previous_editions/last_articles"][lang].toUpperCase()}</p>
                <div className="homepage-article-cards">
                    {edition.articles.map((article, key) => <ArticleCard article={article} key={key} lang={lang} />)}
                </div>

            </div>

        </div>
    )
}

const startupsMoved = () => {
    const news = document.getElementsByClassName("spring-news-startup-div");
    for (let i = 0; i < news.length; i++) {
        news[i].style.transform = `translateX(${100 * (i - currentStartup)}%)`
    }
}

const createMovementForStartup = (delta, arr) => {
    currentStartup += delta;
    document.getElementById("startup-previous").classList.remove("blocked")
    document.getElementById("startup-next").classList.remove("blocked")
    if (currentStartup <= 0) {
        currentStartup = 0;
        document.getElementById("startup-previous").classList.add("blocked")
    }
    if (currentStartup >= arr.length - 1) {
        currentStartup = arr.length - 1;
        document.getElementById("startup-next").classList.add("blocked")
    }
    startupsMoved()
}

const ArticleCard = ({ article,lang }) => {
    return (<a className="homepage-article-card" href={article.link} target="_blank" rel="noreferrer">
        <img src={article.pictureURL} alt="article-img" />
        <p>{article.title[lang]}</p>
    </a>)
}

export { PreviousPage }