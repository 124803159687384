import React, { useContext } from 'react'
import { EditionContext, LangContext } from '../../App'
import './LegalMentionPage.css'
import './LegalMentionPage_Resp.css'

const LegalMentionPage = () => {
    const { edition } = useContext(EditionContext)
    const { lang } = useContext(LangContext)
    return (
        <div className="legal-mentions-page">
            <p className="legal-mentions-title">{edition.translations["legal_mentions/title"][lang].toUpperCase()}</p>
            <div className="legal-mentions-list">
                {edition.legal_mentions.map((mention, index) =>
                    <div className="legal-mention" key={index}>
                        <p>{mention.title[lang]}</p>
                        <p>{mention.desc[lang]}</p>
                    </div>)}
            </div>
        </div>
    )
}

export { LegalMentionPage }